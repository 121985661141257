.side-nav-container-user {
  height: 100%;
  width: 100%;
  /* background-color: #10434f; */
  box-shadow: 3px 0px 16px #777;
}

.side-header-user {
  width: 100%;
  background-color: #10434f;
  /* background-color: #10434F; */
  color: white;
  padding: 1rem 1.5rem 0;
}
.side-header-user h3 {
  font-size: 2rem;
  color: white;
}

.list-container-user {
  /* background-color: var(--secondary-colour); */
  padding-bottom: 1.5rem !important;
  height: 100%;
}

.list-container-user li {
  display: inline-block !important;
}

.list-user {
  display: inline-block !important;
  margin-left: 1rem;
  color: white;
  height: 100%;
  padding: 0px !important;
}

li > a {
  cursor: pointer;
  color: white;
  /* color: #444; */
}

.list-user-item {
  margin-top: 0.2rem;
}
.list-user-item > a {
  position: relative;
  color: #fff !important;
  font-weight: 700 !important;
  margin-left: 2rem;
  right: 1rem;
  padding: 0.7rem 1rem !important;
  background-color: var(--secondary-colour) !important;
  border: white !important;
  border-style: solid !important;
  border-radius: 1rem !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
  text-decoration: none !important;
}

.list-user-item > a:hover {
  text-decoration: none;
  background-color: rgba(250, 63, 8, 0.9) !important;
  color: var(--primary-colour) !important;
  border: var(--primary-colour) !important;
  border-style: solid !important;
}
.list-user-item > a.active {
  color: white !important;
  border: white !important;
  background-color: var(--primary-colour) !important;
  border-style: solid !important;
}
