.circle-group-container-Qstep5{
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content:space-around;
}

.circle-group-labels{
    display: grid;
    place-items: center;
}

.circle-group-container-Qstep5-row{
    width: 100%;
    display: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content:space-around;
    right: 0.5rem;
}

.circle-group-labels-row{
    display: none;
    place-items: center;
    margin-left: 1.5rem;
}

@media (min-width: 667px) {

    .circle-group-container-Qstep5{
        display: none;
    }
    
    .circle-group-labels{
        display: none;
    }

    .circle-group-container-Qstep5-row{
        display: flex;
    }
    
    .circle-group-labels-row{
        display: grid;
    }
    
}

@media (min-width: 760px) {

    .circle-group-container-Qstep5-row{
        right: 1rem;
    }
    
    .circle-group-labels-row{
        margin-left: 2.5rem;
    }
    
}

@media (min-width: 1024px) {

    .circle-group-container-Qstep5-row{
        right: 2.5rem;
    }
    
    .circle-group-labels-row{
        margin-left: 5rem;
    }
    
}

