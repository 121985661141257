.footer-container {
  padding: 0 1.5rem;
  background-color: #062226;
  color: white;
}

.footer-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1140px;
}

.social-media {
  margin-right: 0;
  display: flex;
  flex-direction: column;
}

.social-media-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 1rem;
  margin-right: 1rem;
}

.social-icon {
  cursor: pointer;
  margin: 0 0.5rem;
}

.copyright-container {
  padding-top: 1rem;
  text-align: start;
  width: 100%;
}

.copyright-container > p > a {
  color: #fff;
  cursor: pointer;
  border-bottom: 0.1px solid #fff;
}

.contact-container > p {
  cursor: pointer;
}

.copyright-container,
.disclaimer-container,
.contact-container {
  margin: auto 0;
}

.links {
  display: list-item;
  list-style: none;
}

.slide-link {
  font-size: 0.9rem;
  margin-right: 1rem;
  text-decoration: none !important;
}

.slide-link:hover {
  text-decoration: underline !important;
}

@media (min-width: 640px) {
  .social-media {
    margin-right: 90px;
  }
}

@media (min-width: 660px) {
  .social-media {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 0.75rem;
  }

  .copyright-container {
    padding-top: 0;
    text-align: start;
    width: 50%;
  }

  .copyright-container,
  .disclaimer-container {
    margin: auto 0;
  }

  .disclaimer-container {
    cursor: pointer;
  }
}

@media (min-width: 925px) {
  .social-media {
    margin-left: -11rem;
  }
}

@media (min-width: 1140px) {
  .social-media {
    margin-left: -17rem;
  }
}
