.main-container {
  display: flex;
  margin-top: 1rem;
}

.header {
  max-width: 100%;
}

.header-container {
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home-logo-header {
  padding: 0rem 2rem;
  background-color: var(--primary-colour);
  display: flex;
  align-items: center;
}

.home-logo-header-translation {
  margin-top: 5px;
  margin-right: 2rem;
}

.page-header {
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 10;
  background-color: var(--primary-colour);
}

.tab-steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--primary-colour);
}

.tab-nav-header {
  display: block;
  height: 3rem;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px !important;
}

.tab-nav-header-translation {
  margin-left: 1.5rem;
  margin-right: 8rem;
  position: relative;
  top: 0.8rem;
  width: 6.8rem;
}

.tab-nav-header-item {
  width: 100%;
  position: relative;
  text-align: center;
  justify-content: center;
}

/* invisible first line */
.tab-nav-header-item:first-child::before {
  display: none;
}

.tab-nav-header-item.active + li::before {
  left: 0rem;
  width: 0rem;
}

/* container for each circle step */
.tab-nav-header-navlink {
  display: flex;
  flex-direction: row-reverse;
  flex-flow: column-reverse;
  color: #fff;
  padding: 1rem 0rem 1rem 0rem;
  width: 100%;
  text-decoration: none !important;
}

/* circle position */
.tab-nav-header-navlink > div {
  font-size: var(--font-size-lg);
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 25px;
  margin: auto 0rem;
  left: 1.4rem;
}

/* active number color */
.tab-nav-header-navlink > div > h2 {
  color: var(--primary-colour);
}

/* navlink text */
.tab-nav-header-navlink > p {
  display: none;
}

.tab-nav-header-navlink:hover {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

/* line position */
.tab-nav-header-item::before {
  content: "";
  position: absolute;
  top: 1.7rem;
  left: -1.7rem;
  width: 3.8rem;
  height: 0.5rem;
  background: #fff;
}

.tab-nav-header-item.current + li::before {
  width: 5rem;
}

.complete > .tab-nav-header-navlink > div {
  background-color: #fff;
  color: #fff;
}

.complete > .tab-nav-header-navlink > div > h2 {
  color: #fff;
}

/* Box Outline */
.tab-nav-header-navlink.active {
  color: white;
  background-color: var(--secondary-colour);
  padding-bottom: 1rem;
}

.tab-nav-header-navlink.active h2 {
  color: white;
}

/* Current number */
.current > .tab-nav-header-navlink > div > h2 {
  color: var(--secondary-colour);
}

.tab-nav-header-navlink.active > div > h2,
.tab-nav-header-navlink.active ~ * > div > h2 {
  color: var(--primary-colour);
}

/* previous line of active number */
.tab-nav-header-navlink.active::before {
  background-color: white;
}

/* Treatment Detail Tab = hidden */
.tab-nav-header-navlink-hidden {
  color: white;
  padding: 1rem 0.5rem;
  width: 100%;
}

.tab-nav-header-navlink-hidden.active {
  background-color: var(--secondary-colour);
  width: 100%;
}

.current > .tab-nav-header-navlink-hidden.active > div > h2 {
  color: #fff;
}

.tab-nav-header-navlink-hidden.active ~ *::before {
  background-color: #fff;
}

.tab-nav-header-navlink-hidden.active ~ * > div {
  background-color: #fff;
}

.tab-nav-header-navlink-hidden.active ~ * > div > h2 {
  color: var(--primary-colour);
}

.tab-nav-header-navlink-hidden.active::before {
  background-color: #fff;
}

.logo-home {
  height: 8rem;
}

.logo-link {
  position: relative;
  height: 6.8rem;
  margin-top: -1rem;
}

.home-subheader-content h3 {
  color: white;
}

@media (min-width: 320px) {
  .tab-nav-header-item::before {
    left: -0.3rem;
    width: 1.7rem;
  }
}

@media (min-width: 375px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 4.7rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -0.6rem;
    width: 2rem;
  }
}

@media (min-width: 384px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 4.9rem;
  }
}

@media (min-width: 406px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 5.2rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -1.1rem;
    width: 3rem;
  }
}

@media (min-width: 450px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 5.7rem;
  }

  .tab-nav-header-navlink > div {
    left: 1.7rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -2.6rem;
    width: 6.2rem;
  }
}

@media (min-width: 498px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 6.3rem;
  }

  .tab-nav-header-navlink > div {
    left: 2rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -2.9rem;
    width: 6.7rem;
  }
}

@media (min-width: 538px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 6.8rem;
  }

  .tab-nav-header-navlink > div {
    left: 2.5rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -2.9rem;
    width: 7.3rem;
  }
}

@media (min-width: 576px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 7.2rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -3.3rem;
    width: 7.7rem;
  }
}

@media (min-width: 600px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 7.5rem;
  }

  .tab-nav-header-navlink > div {
    left: 2.8rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -3.3rem;
    width: 8.1rem;
  }
}

@media (min-width: 640px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 8rem;
  }
}

@media (min-width: 648px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 8.2rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -3.98rem;
    width: 8.8rem;
  }
}

@media (min-width: 667px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 8.5rem;
  }

  /* circle position */
  .tab-nav-header-navlink > div {
    left: 3rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -4rem;
    width: 8.9rem;
  }
}

@media (min-width: 713px) {
  /* circle position */
  .tab-nav-header-navlink {
    width: 9rem;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -4.5rem;
    width: 9.4rem;
  }
}

@media (min-width: 720px) {
  .header {
    height: 110px;
  }

  .logo-home {
    height: 10rem;
    padding-left: 8rem;
  }

  .logo-link {
    /* margin-left: -6rem; */
  }

  .header-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .tab-nav-header {
    height: 6.9rem;
    margin-bottom: 0rem;
  }

  .tab-steps {
    width: 80%;
    height: 100%;
  }

  /* container for each circle step */
  .tab-nav-header-navlink {
    position: relative;
    top: 0rem !important;
    padding: 0rem 0rem 0rem 0rem;
    height: 100%;
    width: 100%;
  }

  /* circle position */
  .tab-nav-header-navlink > div {
    margin: 0rem auto !important;
    left: 0rem;
    z-index: 1;
  }

  .tab-nav-header-navlink.active > div {
    position: relative;
    top: 0.5rem;
  }

  /*container of all the steps */
  .tab-nav-header-item {
    right: 0rem;
    top: 0.5rem;
    width: 100%;
  }

  /* the lines */
  .tab-nav-header-item::before {
    left: -1.8rem;
    top: 3.2rem;
    width: 4.8rem;
  }

  .tab-navlink-div {
    justify-content: center;
  }

  .tab-navlink-div-fr {
    justify-content: center;
  }
}

@media (min-width: 756px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -2.5rem;
    width: 5.3rem;
  }
}

@media (min-width: 780px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -2.6rem;
    width: 5.5rem;
  }
}

@media (min-width: 830px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -2.9rem;
    width: 6.1rem;
  }
}

@media (min-width: 880px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -3.1rem;
    width: 6.5rem;
  }
}

@media (min-width: 930px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -3.4rem;
    width: 7.1rem;
  }
}

@media (min-width: 975px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -3.5rem;
    width: 7.5rem;
  }
}

@media (min-width: 1020px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -3.8rem;
    width: 7.9rem;
  }
}

@media (min-width: 1070px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -4.1rem;
    width: 8.5rem;
  }
}

@media (min-width: 1110px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -4.3rem;
    width: 8.9rem;
  }
}

@media (min-width: 1160px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -4.7rem;
    width: 9.3rem;
  }
}

@media (min-width: 1200px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -4.8rem;
    width: 9.7rem;
  }
}

@media (min-width: 1240px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -5rem;
    width: 10.1rem;
  }
}

@media (min-width: 1280px) {
  /* active circle */
  .tab-nav-header-navlink.active > div {
    top: 1.5rem;
  }

  .tab-nav-header-navlink.active > p {
    top: 2rem !important;
  }

  /* circle position */
  .tab-nav-header-navlink > div {
    margin: 0rem auto !important;
    left: 0rem;
    top: 1rem;
  }

  /* navlink text */
  .tab-nav-header-navlink > p {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    font-size: 14px;
    top: 1.5rem;
  }

  /* active navlink text */
  .tab-nav-header-navlink.active p {
    top: 0.6rem;
  }

  /* line */
  .tab-nav-header-item::before {
    left: -5.4rem;
    width: 10.5rem;
  }

  /* active circle for step 2 in french */
  .tab-nav-header-navlink-fr-2.active > div {
    top: 2.1rem !important;
  }

  .tab-navlink-div-fr {
    position: relative;
    top: 1.6rem !important;
  }
}

@media (min-width: 1360px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -5.8rem;
    width: 11.3rem;
  }
}

@media (min-width: 1450px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -6rem;
    width: 11rem;
  }
}

@media (min-width: 1474px) {
  /* active circle for step 2 in french */
  .tab-nav-header-navlink-fr-2.active > div {
    top: 1.45rem !important;
  }

  .tab-navlink-div-fr {
    position: relative;
    top: 0.95rem !important;
  }
}

@media (min-width: 1545px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -6.8rem;
    width: 13.1rem;
  }
}

@media (min-width: 1635px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -7.5rem;
    width: 14.1rem;
  }
}

@media (min-width: 1720px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -6.8rem;
    width: 14.9rem;
  }
}

@media (min-width: 1815px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -7.3rem;
    width: 15.9rem;
  }
}

@media (min-width: 1895px) {
  /* the lines */
  .tab-nav-header-item::before {
    left: -7.7rem;
    width: 15rem;
  }
}
