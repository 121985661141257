.summary-header {
    padding: 1.5rem;
    background-color: var(--light);
}
  
.summary-header-content {
    max-width: 1140px;
    margin: 0 auto;
}
  
.summary-notice {
    margin: 0;
    display: inline-block;
    flex-direction: row;
    justify-content: space-between;
}
  
.summary-notice>div:last-of-type>Button {
    margin-top: 0.5rem !important;
}
  
.summary-container h5 {
    margin: 2rem 0 0.25rem;
}  

.circle-radio-group-summary {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 19rem;
    margin: 0 auto;
    left: 10px;
    bottom: 10px;
}

.circle-radio-group-summary > h5{
    font-size: 16px !important;
}

.circle-radio-group-summary-no-zero {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 19rem;
    margin: 0 auto;
    left: 15px;
    bottom: 10px;
}

.circle-radio-group-summary-no-zero > h5{
    font-size: 16px !important;
}

.circle-radio-group-container{
    display: flex;
}

.circle-radio-group-container-no-zero {
    display: flex;
}

.circle-radio-group-img{
    position: relative;
    width: 17rem;
    height: auto;
    left: 1rem;
}

.circle-radio-group-img-no-zero{
    position: relative;
    width: 18rem;
    height: auto;
    left: 0.5rem;
}

.number-rating{
    font-size: 12px;
}

@media (min-width: 412px) {

    .circle-radio-group-summary {
        width: 20rem;
        left: 1rem;
    }

    .circle-radio-group-summary-no-zero {
        width: 20rem;
    }

    .circle-radio-group-img{
        width: 20rem;
    }

    .circle-radio-group-img-no-zero{
        width: 20rem;
    }
}

@media (min-width: 667px) {

    .number-rating{
        font-size: 18px;
    }

    .circle-radio-group-summary > h5{
        font-size: 18px !important;
    }

    .circle-radio-group-summary-no-zero > h5{
        font-size: 18px !important;
    }

    .circle-radio-group-summary {
        width: 33rem;
    }

    .circle-radio-group-summary-no-zero {
        width: 33rem;
    }

    .circle-radio-group-img{
        width: 33rem;
    }

    .circle-radio-group-img-no-zero{
        width: 34rem;
    }
}

@media (min-width: 760px) {

    .number-rating{
        font-size: 18px;
    }

    .circle-radio-group-summary > h5{
        font-size: 20px !important;
    }

    .circle-radio-group-summary-no-zero > h5{
        font-size: 20px !important;
    }

    .circle-radio-group-summary {
        width: 40rem;
    }

    .circle-radio-group-summary-no-zero {
        width: 38rem;
    }
    
    .circle-radio-group-img{
        width: 40rem;
    }

    .circle-radio-group-img-no-zero{
        width: 40rem;
    }
}

@media (min-width: 844px) {
    
    .circle-radio-group-summary {
        width: 40rem;
        left: -2rem;
    }

    .circle-radio-group-summary-no-zero {
        width: 40rem;
        left: -1rem;
    }
}

@media (min-width: 915px) {
    
    .circle-radio-group-summary {
        width: 42rem;
        left: -3rem;
    }

    .circle-radio-group-summary-no-zero {
        left: -3rem;
    }
}

@media (min-width: 1024px) {
    
    .circle-radio-group-summary {
        left: -9rem;
    }

    .circle-radio-group-summary-no-zero {
        width: 40rem;
        left: -6rem;
    }
}

@media (min-width: 1080px) {

    .circle-radio-group-summary-no-zero {
        width: 40rem;
        left: -8rem;
    }
}

@media (min-width: 1280px) {

    .circle-radio-group-summary {
        left: -11rem;
    }

    .circle-radio-group-summary-no-zero {
        width: 40rem;
        left: -10rem;
    }
}