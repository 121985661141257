.wrapper {
    margin-top: 3.5rem;
}

.notice-container>h5{
    display: grid;
    position: relative;
    text-align: center;
    bottom: 50px;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
}