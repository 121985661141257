.sub-header {
  background-color: var(--secondary-colour);
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  position: fixed;
  top: 110px;
  width: 100%;
  z-index: 10;
}
.page-subheader {
  margin-top: 10.5rem;
}

.subheader-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subheader-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
}
.subheader-content a {
  color: #fff;
  margin-right: 1rem;
}
.subheader-content a:hover {
  text-decoration: none;
  color: #fff;
}
.subheader-content > h3 {
  font-size: var(--font-size-sm);
  color: #fff;
}
.subheader-btn {
  position: relative;
  color: #fff !important;
  font-weight: 700 !important;
  margin-left: 1rem;
  right: 1rem;
  padding: 0.7rem 1rem !important;
  background-color: var(--secondary-colour) !important;
  border: white !important;
  border-style: solid !important;
  border-radius: 1rem !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
}
.subheader-btn:hover {
  text-decoration: none;
  background-color: rgba(250, 63, 8, 0.9) !important;
  color: var(--primary-colour) !important;
  border: var(--primary-colour) !important;
  border-style: solid !important;
}

@media (min-width: 430px) {
  .subheader-content > h3 {
    font-size: var(--font-size-md);
  }
}

@media (min-width: 720px) {
  .page-subheader {
    margin-top: 6.8rem;
  }
}

@media (min-width: 768px) {
  .subheader-content {
    flex-direction: row;
    align-items: center;
  }

  .subheader-content > h3 {
    font-size: var(--font-size-lg);
  }
}

@media (min-width: 1140px) {
  .sub-header {
    background-color: var(--secondary-colour);
    padding: 0.5rem;
    color: #fff;
  }
  .subheader-content > h3 {
    margin-left: 1.5rem;
  }
}

@media (max-width: 720px) {
  .sub-header {
    top: 160px;
  }
}
