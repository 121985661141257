* {
  margin: 0;
  padding: 0;
  border: 0;
}

li {
  list-style: none;
}

.chartWrapper {
  margin-bottom: 300px;
}

:root {
  --primary-colour: #10434f;
  --secondary-colour: #f15a2b;
  --secondary-colour-hover: #ffa67d;
  --grey-colour: #e2e3e5;
  --light: #f8f9fa;
  --red-colour: #ea3916;

  --font-size-xxlg: 2rem;
  --font-size-xlg: 1.802rem;
  --font-size-lg: 1.602rem;
  --font-size-md: 1.424rem;
  --font-size-sm: 1.266rem;

  --default-border-radius: 0.9375rem;
}

h1 {
  font-size: var(--font-size-xxlg) !important;
}

h2 {
  font-size: var(--font-size-xlg) !important;
}

h3 {
  font-size: var(--font-size-lg) !important;
}

h4 {
  font-size: var(--font-size-md) !important;
}

h5 {
  font-size: var(--font-size-sm) !important;
}

p {
  font-size: 18px;
}

.notice {
  padding: 1rem auto;
  margin: 0 2rem 1rem 2rem;
  background-color: var(--light);
  border-radius: var(--default-border-radius);
}

.Notice {
  padding: 1rem 1rem 0.5rem;
  margin: 0 2rem 1rem 2rem;
  background-color: var(--light);
  border-radius: var(--default-border-radius);
}

.text-center > img {
  padding: 2rem;
}

.disclaimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--light);
  border-radius: var(--default-border-radius);
  text-align: center;
}

.disclaimer > img {
  max-width: 75px;
  max-height: 75px;
  margin: 1rem;
}

.disclaimer > p {
  margin: auto 0;
  padding-left: 1rem;
}

.light-disclaimer {
  background-color: #fff;
}

.step-one-expansion {
  color: #fff !important;
  border-radius: 0.9375rem !important;
  background-color: var(--primary-colour) !important;
}

.step-one-expansion-content {
  background-color: #fff !important;
  justify-content: center !important;
}

.step-one-expansion-icon {
  color: #fff !important;
}

.numbered-subheading {
  text-align: left !important;
  width: 100%;
  color: #fff;
  background-color: var(--primary-colour);
  padding: 1rem 1.5rem;
  margin: 0rem 0rem;
  border-radius: 0.9375rem;
  font-size: 18px;
}

.study-numbered-subheading {
  width: 100%;
  color: #fff;
  background-color: var(--secondary-colour);
  padding: 1rem 1rem 2rem 1rem;
  transition: all 350ms ease-in-out;
  cursor: pointer;
}

.study-numbered-subheading:hover {
  color: var(--primary-colour);
  background-color: var(--secondary-colour-hover);
  transform: translateY(-10px);
}

.text-big {
  font-size: 20px !important;
  font-weight: bolder;
  color: #1d2124;
}

.box-container {
  margin: 2rem auto;
  padding: 1.5rem 0.75rem;
  overflow-x: hidden;
}

.box-container-numbered {
  margin: 2rem 0rem;
  background-color: #ffff;
  box-shadow: 5px 10px 10px 10px rgb(0, 0, 0, 0.3);
  border-radius: 16px;
}

.research-subheadings {
  position: relative;
  top: 20px;
}

.padding-class {
  padding: 1rem 1.5rem;
}

/* .save-btn-container{
  display: flex;
} */
/* .save-btn-container .next-btn{
  margin-left: auto;
} */

.hidden {
  display: none;
}

/********************
      HEADER
********************/
.home-page-header {
  top: 0%;
  max-width: 100%;
  overflow-x: hidden;
  z-index: 10;
  /* background-color: var(--primary-colour); */
}

.btn-container {
  text-align: center;
}

.btn-container-elem {
  position: relative;
  display: inline-block;
}

.current::before,
.complete::before {
  background: var(--secondary-colour);
}

.home-subheader-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  color: #fff;
}

/********************
      CARDS
********************/

.card-style {
  min-height: 20rem;
  border-radius: 22px !important;
  margin: 1rem auto;
  overflow: hidden;
}

.card-title {
  min-height: 3rem;
}

.card-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.card-style a {
  text-decoration: underline;
}

.card-style a:hover {
  color: var(--primary-colour);
}

/* .card-style:hover img {
  transform: scale(1.05);
} */

.card-img-container {
  overflow: hidden;
  border-radius: 22.34px 22.34px 0 0 !important;
}

.cardImg {
  max-height: 200px;
  object-fit: cover;
  transition: all 0.75s linear;
  border-radius: 22.34px 22.34px 0 0 !important;
  height: 200px;
}

.card-title-intro {
  text-align: center;
}

.underline {
  margin: 0 auto 1rem;
  width: 30%;
  border-top: 4px solid var(--red-colour);
}

.card-row {
  display: flex;
}

.card-link {
  text-decoration: none !important;
  color: white;
  font-weight: 600;
}

.card-link:hover {
  color: var(--secondary-colour) !important;
}

/********************
      BUTTONS
********************/
.next-btn-container {
  color: #fff;
  text-align: center;
  margin: 12rem 0 0;
  padding: 2rem 1.5rem;
  background-color: var(--primary-colour);
}

/* split p into two lines */
.next-btn-container p {
  white-space: pre-line;
}

.back-button-container {
  text-align: left;
}

.back-button-container:last-of-type {
  padding-bottom: 2rem;
}

.next-btn-content {
  max-width: 1140px;
  margin: 0 auto;
}

.next-btn {
  color: #fff !important;
  font-weight: 700 !important;
  padding: 0.7rem 3rem !important;
  background-color: var(--secondary-colour) !important;
  border: none !important;
  border-radius: 1rem !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
  width: auto;
}

@media screen and (min-width: 506px) {
  .add-btn {
    margin-left: 20px;
  }
}

.next-btn:hover {
  text-decoration: none;
  background-color: rgba(241, 90, 43, 0.9) !important;
}

.new-assessment-button {
  color: white;
}

.newnext-btn-container {
  color: var(--primary-colour) !important;
  text-align: center;
  margin: 0rem 0 0;
  padding: 1rem 1.5rem;
}

.newnext-btn-content {
  max-width: 1140px;
  margin: 0 auto;
  color: var(--primary-colour) !important;
}

.newnext-btn {
  color: #fff !important;
  font-weight: 700 !important;
  padding: 0.7rem 3rem !important;
  background-color: var(--primary-colour) !important;
  border: none !important;
  border-radius: 1rem !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
}

/********************
     SLIDER DIV
********************/
.slider-div {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 2.5rem 0 0 1rem;
}

.slider-div > p {
  display: inline-block;
  position: absolute;
}

.slider-div > p:first-of-type {
  left: 1rem;
}

.slider-div > p:last-of-type {
  right: 1rem;
}

.normal span.MuiSlider-rail {
  background: linear-gradient(
    to right,
    #ae0721 0%,
    #e82727 30%,
    #ffe815 60%,
    #45a735 75%
  );
}

.reversed span.MuiSlider-rail {
  background: linear-gradient(
    to left,
    #ae0721 0%,
    #e82727 30%,
    #ffe815 60%,
    #45a735 75%
  );
}

/********************************************
                HOME SECTION
*********************************************/
.home-header {
  text-align: center;
  color: #fff;
}

.main-options > h1 {
  margin: 0;
  text-align: center;
  font-weight: bold;
}

.main-options > .row {
  margin: 0;
}

.main-options .card {
  height: 550px;
  margin: 2rem auto 0 !important;
}

/********************************************
            QUESTIONNAIRE SECTION
*********************************************/
.qIntro-main-div > div:first-child {
  margin: 2rem auto;
  text-align: center;
}

.steps-img {
  width: 80%;
  margin: 2rem;
}

#Front,
#Back {
  width: 21rem;
}

#first,
#second,
#third,
#fourth,
#fifth,
#sixth,
#study {
  margin: 2rem 0 0;
  cursor: pointer;
}

#sixth {
  margin-bottom: 4rem;
}

#study_0,
#study_1 {
  margin: 0;
  cursor: pointer;
}

#study_1 {
  margin-top: 1.5rem;
}

#second > p,
#first > p,
#third > p,
#fourth > p,
#fifth > p,
#sixth > p {
  margin: 0;
}

.collapse-all {
  text-align: right;
  text-decoration: underline;
  color: var(--primary-colour);
  cursor: pointer;
}

.collapsed-icon {
  float: right;
  font-size: 0.8rem;
  min-width: fit-content;
  margin: auto 0;
  position: relative;
  left: 0rem;
}

/***********************
    STEP TWO/THREE
***********************/
.StepTwo-div > div > Button,
.StepThree-div > div > Button {
  color: var(--primary-colour);
  border: 3px solid var(--primary-colour);
  border-radius: 2rem;
  padding: 1rem;
  margin: 1rem 0.5rem 1rem 1.5rem;
}

.StepTwo-div > div > Button:hover,
.StepThree-div > div > Button:hover {
  transition-duration: 0.4s;
  background-color: rgba(16, 67, 79, 0.2);
}

.Step-subHeading {
  width: 100%;
  padding: 0.75rem;
  color: #000;
  border-radius: 0.9375rem 0.9375rem 0 0;
  margin-left: 10px;
}

.text-left-right {
  text-align: right;
  position: relative;
  padding: 0.75rem;
  border-radius: 0.9375rem 0.9375rem 0 0;
}

.left-text {
  left: 0;
  position: absolute;
  margin-left: 100px;
  color: #008000;
}

.byline {
  color: #dc143c;
  margin-right: 95px;
}

.StepTwo-h3,
.StepTwo-desc,
.StepThree-h3,
.StepThree-desc,
.StepFour-div-group {
  margin-top: 1rem;
}

.StepTwo-div,
.StepThree-div,
.Step-group-container {
  margin: 2.25rem auto;
  padding-bottom: 1rem;
  border-radius: 0.9375rem;
  box-shadow: 0 0 6px rgba(26, 24, 24, 0.79);
}

/********************
      STEP FOUR
*********************/
.StepFour-div,
.StepFour-div-other {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.StepFour-div > div,
.StepFour-div-other > div {
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  border: 3px solid var(--primary-colour);
  margin-bottom: 1.5rem;
}

.StepFour-div > div:hover,
.StepFour-div-other > div:hover {
  background-color: rgba(64, 140, 160, 0.4);
  cursor: pointer;
}

.text-field {
  width: 100%;
  margin-top: 6px !important;
}

#GotIt-div > p {
  text-align: center;
  width: 175px;
  height: 50px;
  color: #fff !important;
  font-weight: 800 !important;
  padding: 0.7rem 2rem !important;
  margin: 1rem auto !important;
  background-color: var(--secondary-colour) !important;
  border: none !important;
  border-radius: 1rem !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.16);
}

#GotIt-div > p:hover {
  cursor: pointer;
}

/********************************************
                REVIEW SECTION
*********************************************/
.heart:hover {
  cursor: pointer;
  /* fill: rgba(255, 0, 30, 0.687) !important; */
}

.add-icon:hover {
  cursor: pointer;
  fill: hsla(15, 88%, 55%, 0.7);
}

.card-bottom {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.icon-container span {
  display: block;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.icon-text {
  font-size: 12px;
  color: #939598;
  width: 8rem;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
}

input[type="checkbox"] {
  width: 30px;
  height: 30px;
}

.plan-icon {
  width: 6.5rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.review-box-container {
  min-height: 500px;
  padding: 2rem 1rem;
  border: 0.2rem solid var(--primary-colour);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
}

.disclaimer {
  justify-content: center;
}

.review-active h4 {
  color: var(--primary-colour);
}

.title-padding {
  padding: 2rem 1rem 0.5rem 1rem;
}

.title-padding > h4 {
  color: var(--secondary-colour);
  font-weight: bold;
}

.review-content {
  padding: 1rem 2rem;
  min-height: 25vh;
}

.all-treatments-review {
  padding: 0;
}

.review-description-div {
  background-color: var(--light);
  margin: 2rem;
  padding-top: 1rem;
  border-radius: var(--default-border-radius);
}

.top-three-container {
  padding: 2rem;
  margin-top: 2rem;
  border: 1px solid #fff;
  border-radius: 1rem;
  box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.16);
}

.expandable-treatment {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  object-fit: cover;
}

.expandable-treatment > img {
  width: 15%;
  height: 95%;
  object-fit: cover;
}

.expandable-treatment > p {
  margin-left: 2rem;
  margin-bottom: 0;
}

.topTreatments-row {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  padding: 0 1rem;
  align-items: center;
  border: 1px solid black;
}

/*All treatments starts*/
.AllTreatments_treatment_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.AllTreatments_treatment_div p {
  margin: auto 0;
  font-weight: 700;
}

.AllTreatments_treatment_div > span {
  font-size: 1.5rem;
}

.favourites {
  display: flex;
  flex-direction: column;
  margin: 2rem auto !important;
}

/************************************
      TREATMENT DETAILS STARTS
************************************/
/* traffic light */

#traffic_and_description {
  position: relative;
}

.trafficLight_svg {
  width: 5rem;
  position: absolute;
  top: -20px;
}

#green-light {
  fill: #7fb041;
}

#red-light {
  fill: #e93b25;
}

#yellow-light {
  fill: #fcb41a;
}

#image_and_description {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 1rem;
}

#image_and_description > img {
  border-radius: 15px;
  width: 100%;
}

.description-title {
  display: flex;
  align-items: center;
}

.description-title span {
  margin-top: 2rem;
  margin-left: 0.5rem;
}

/* traffic light section */
#traffic_and_description {
  display: flex;
  margin: 2rem auto;

  border-radius: 15px;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.traffic-description {
  padding: 0.5rem 1rem 0.5rem 3rem;
}

.traffic-description p {
  font-size: 18px;
}

#treatment_details_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.treatment-details-texts {
  padding: 1.5rem;
}

.treatment-details-texts h3 {
  margin-top: 1.5rem;
  font-weight: bold;
}

.treatment-details-texts h4 {
  font-weight: bold;
}

/*Study section*/
.icon-containerstudy {
  position: relative;
  text-align: center;
}

.icon-container {
  position: relative;
  bottom: 0.2rem;
}

.material-icons {
  color: var(--primary-colour);
}

.material-icons:hover {
  color: rgba(241, 90, 43, 0.8);
  transition-duration: 0.5s;
  cursor: pointer;
}

.info-icon {
  font-size: 65px !important;
  position: relative;
  top: 3rem;
  display: grid;
  margin: auto;
}

.print-icon {
  margin-left: 0.5rem;
}

.study-container {
  margin: 1rem 1.5rem;
  padding: 1rem 1.5rem 5rem 0rem;
  cursor: auto;
}

.study-container > h5 {
  font-weight: bold;
  color: var(--primary-colour);
}

p.collapsed-icon {
  font-size: 2.5rem;
}

.startContainer-half > h5 {
  font-weight: bold;
  color: var(--primary-colour);
}

.startContainer-half > P {
  margin: 0;
}

.starContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5rem;
}

.starMessage-Container {
  padding-bottom: 1.5rem;
}

.treatment_details_overview {
  padding: 0 1.5rem;
}

.treatment_details_overview > h3 {
  font-weight: bold;
}

.treatment_details_study_container {
  width: 100%;
}

.treatment_details_study_container > h5 {
  padding: 0 1.5rem 0;
}

.treatment_details_studies > p {
  color: white;
}

.treatment_details_links {
  word-wrap: break-word;
}

.save-as-favourite {
  display: flex;
  align-items: center;
}

.save-as-favourite span {
  margin-top: 2rem;
  margin-left: 0.5rem;
}

.research_heading > h3 {
  font-weight: bold;
}

/********************************************
                PLAN SECTION
*********************************************/
.plan-top-notice {
  margin-top: 1rem;
}

.plan-container {
  padding-bottom: 0.75rem;
}

.plan_factors,
.plan_steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  padding: 1rem 1rem;
  border-radius: 1rem;
  border: 2px solid rgba(196, 196, 196, 0.3);
}

.plan_factors div,
.plan_steps div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.padding-class .plan_factors h5,
.padding-class .plan_steps h5 {
  margin: 0 1rem 0rem;
  margin-top: 0;
}

.plan_factors p,
.plan_steps p {
  margin: 0.5rem 1.75rem 0;
}

.plan_factors input,
.plan_steps input {
  cursor: pointer;
}

.review_key_facts {
  text-align: center;
}

.review_key_facts > h5:first-of-type {
  margin-top: 1.5rem;
}

.review_key_facts > p:last-of-type {
  padding-bottom: 1.25rem;
}

.radio-group {
  display: flex !important;
  justify-content: center !important;
}

.radio-button {
  margin: 1.5rem 2rem !important;
}

/********************************************
                SUMMARY SECTION
*********************************************/
.hidden-print-container {
  position: relative;
  overflow: hidden;
}

.save-container {
  margin: 4rem 0 0;
  padding: 2rem 1.5rem;
  background-color: var(--primary-colour);
}

.summary-notice > div:last-of-type > Button {
  margin: 3rem 0 0 0.5rem !important;
}

.hidden-print {
  position: absolute;
  top: -200%;
  left: 200%;
  z-index: 0;
}

.pdf-page,
.sc-pdf,
.hcp-pdf,
.favs-pdf,
.plan-pdf {
  padding: 1rem 1.5rem 4rem;
  width: 250mm;
  min-height: 297mm;
}

.pdf-page > h5 {
  margin: 1rem 0;
}

.pdf-page > li {
  list-style: disc;
  text-indent: 2rem;
}

/******************************************
              MEDIA QUERIES
*******************************************/

/******************************************
             min-width: 430px
*******************************************/
@media (min-width: 430px) {
  .summary-notice > div:last-of-type > Button {
    margin-top: 2em !important;
  }

  .subheader-content > h3 {
    font-size: var(--font-size-md) !important;
  }
}

/******************************************
             min-width: 660px
*******************************************/
@media (min-width: 640px) {
  .numbered-subheading {
    font-size: var(--font-size-md);
    display: flex;
    justify-content: space-between;
  }

  .study-numbered-subheading {
    font-size: var(--font-size-md);
    display: flex;
    justify-content: space-between;
  }

  .disclaimer {
    flex-direction: row;
    text-align: left;
    margin: 0 auto;
  }

  .disclaimer > img {
    margin-right: 1rem;
  }

  /*MEDIA 660PX TREATMENTS DETAILS START*/
  #image_and_description {
    flex-direction: row;
  }

  #image_and_description > .description {
    margin: 0 0 0 1rem;
  }

  #image_and_description > img {
    border-radius: 15px;
    width: 200px;
    height: 150px;
  }

  #traffic_and_description {
    margin: 3rem 0 4rem 0;
  }

  .trafficLight_svg {
    width: 10rem;
    position: absolute;
    top: -44px;
  }

  #traffic_and_description {
    margin: 4rem auto;
    justify-content: left;
    border-radius: 15px;
  }

  .traffic-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding: 1.5rem 2rem 1.5rem 6rem;
  }

  #Front,
  #Back {
    width: 40rem;
  }

  .summary-notice > div:last-of-type > Button {
    margin-top: 1.5rem !important;
  }
}

/******************************************
                768px iPad
*******************************************/
@media (min-width: 768px) {
  .subheader-content {
    flex-direction: row;
  }

  .StepFour-div-group {
    display: flex;
    justify-content: space-evenly;
  }

  .all-treatments-review:last-child {
    padding-bottom: 1.5rem;
  }

  .steps-img {
    width: 90%;
  }

  .intro .disclaimer {
    margin: 5.3rem 0;
  }

  .subheader-content > h3 {
    font-size: var(--font-size-lg) !important;
  }

  .main-options .card {
    width: 100%;
  }

  .main-options > .row > .col-md-6:last-of-type {
    margin: 0 auto !important;
  }

  .box-container {
    box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.16);
    border-radius: 0.9375rem;
  }

  /* Sliders */
  .slider-div {
    position: relative;
    width: 95%;
    margin-left: 0.5rem;
  }

  .slider-div > p {
    position: absolute;
  }

  .slider-div > p:first-of-type {
    left: 2.5rem;
  }

  .slider-div > p:last-of-type {
    right: 2.5rem;
  }

  .numbered-subheading {
    margin: auto 0;
    border-radius: 0.9375rem;
  }

  .study-numbered-subheading {
    margin: auto 0;
  }
}

/******************************************
                 @ 925px
*******************************************/
@media (min-width: 925px) {
  .intro .disclaimer {
    margin: 0;
  }

  .numbered-subheading {
    font-size: var(--font-size-lg);
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
  }

  .study-numbered-subheading {
    font-size: var(--font-size-lg);
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
  }

  /* Sliders */
  .slider-div {
    width: 100%;
  }

  .slider-div > p:first-of-type {
    left: 7rem;
  }

  .slider-div > p:last-of-type {
    right: 7rem;
  }

  .card-img-container {
    overflow: hidden;
    border-radius: 22.34px 22.34px 0 0 !important;
  }

  .cardImg {
    max-height: 200px;
    object-fit: cover;
    transition: all 0.75s linear;
    border-radius: 22.34px 22.34px 0 0 !important;
    height: 200px;
  }

  .card-title-intro {
    text-align: center;
  }

  .underline {
    margin: 0 auto 1rem;
    width: 30%;
    border-top: 4px solid var(--red-colour);
  }

  .summary-notice > div:last-of-type > Button {
    margin-top: 1rem !important;
  }
}

/******************************************
                 @ 1140px
*******************************************/
@media (min-width: 1140px) {
  .numbered-subheading {
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
  }

  .sub-header {
    background-color: var(--secondary-colour);
    padding: 0.5rem;
    color: #fff;
  }

  .subheader-content > h3 {
    margin-left: 1.5rem;
  }

  .main-options {
    margin: 0 auto 3rem auto;
  }

  .box-container {
    padding: 1rem 1.5rem;
    margin: 1rem auto;
    border-radius: 0.9375rem;
  }

  .all-treatments-col {
    display: flex;
    justify-content: space-around;
  }

  /* Sliders */
  .slider-div > p:first-of-type {
    left: 12rem;
  }

  .slider-div > p:last-of-type {
    right: 12rem;
  }

  #image_and_description {
    padding: 1rem 0 1rem;
  }

  #image_and_description > img {
    width: 250px;
    height: 175px;
  }

  .treatment_details_overview,
  .treatment_details_study_container > h5,
  .treatment-details-texts {
    padding: 1rem 0 0;
  }

  .review-content {
    margin: 0 1.5rem 4rem;
  }

  .summary-notice > div:last-of-type > Button {
    margin-top: 0.5rem !important;
  }
}

.subheader-content > h3 {
  color: #000 !important;
  font-weight: bold;
}
