.accordion {
  --bs-accordion-active-color: white !important;
  --bs-accordion-active-bg: #f15a2b !important;
  box-shadow: 5px 10px 10px 10px #0000004d;
  border-radius: 0.375rem !important;
  --bs-accordion-btn-focus-box-shadow: 0 !important;
  margin-top: 25px !important;
}

.accordion:empty {
  display: none;
}

.accordion-body {
  padding-bottom: 0 !important;
}

.accordion-body > p {
  margin: 0 !important;
}

.accordion-body > p > a {
  word-wrap: break-word !important;
}

.accordion-collapse:last-child > .accordion-body {
  padding-bottom: 16px !important;
}

.accordion-button {
  color: white !important;
  background-color: #f15a2b !important;
  font-weight: 500;
}

.accordion-button:hover {
  color: #10434f !important;
  background-color: #ffa67d !important;
}

.accordion-item {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.accordion-item:last-child {
  border: none !important;
}

.collapse-text {
  position: absolute;
  right: 50px;
}

.download-btn-top {
  margin-left: 0rem;
}

.download-btn-btm {
  margin-left: 0rem;
}

.subheader-content-treatments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1140px;
  margin: 8rem auto 0 auto;
}

.subheader-content-treatments a {
  color: #fff;
  margin-right: 1rem;
}
.subheader-content-treatments a:hover {
  text-decoration: none;
  color: #fff;
}
.subheader-content-treatments > h3 {
  font-size: var(--font-size-sm);
  color: #fff;
}

.treatment_details_links {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

@media (max-width: 460px) {
  .accordion-button {
    flex-direction: column !important;
    word-wrap: break-word !important;
  }

  .collapse-text {
    position: static;
    margin-left: auto;
  }
}

@media (min-width: 667px) {
  .download-btn-top {
    float: right;
  }

  .download-btn-btm {
    float: right;
    margin-left: 15rem;
  }
}

@media (min-width: 760px) {
  .subheader-content-treatments {
    margin: 4rem auto 0 auto;
  }

  .download-btn-btm {
    float: right;
    margin-left: 21rem;
  }
}

@media (min-width: 1024px) {
  .download-btn-btm {
    float: right;
    margin-left: 38rem;
  }
}

@media (min-width: 1280px) {
  .download-btn-btm {
    float: right;
    margin-left: 42rem;
  }
}
